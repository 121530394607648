import React from 'react';

function CalculationNameEditModal({ setShowCalculationNameEditFlag, calculationName, handleChange }) {
    let calculationNameText = calculationName;
    function closeModal(){
        setShowCalculationNameEditFlag(false);
    }

    function setCalculationName(text){
        calculationNameText = text;
    }

    function updateCalculationName(field, value){
        handleChange(field, value);
        closeModal();
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>Edit Calculation Name</a>
                    <div>
                        <a className={"modal-input-label"}>Name:</a>
                        <input className="exerciseName" style={{marginTop: "20px", marginBottom: "10px"}} size={49} defaultValue={calculationNameText} onChange={e => setCalculationName(e.target.value)} />
                    </div>

                    <div style={{display: "flex", margin: "auto"}}>
                        <button className="normal-button-system" onClick={() => updateCalculationName("name", calculationNameText)} style={{width: "160px", margin: "12px 12px 12px 0"}}>Update Name</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalculationNameEditModal;