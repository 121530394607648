import React, {useState} from 'react';
import CalculationNameEditModal from "./CalculationNameEditModal";

function CalculatorModal({setShowCalculatorFlag, addCaloricDataCaloriesNeeded, calculatorModalValues, setCalculatorModalValues, addCalculation, calculatorModalEditMode, updateCalculation, deleteCalculation}) {

    const [showCalculationNameEditFlag, setShowCalculationNameEditFlag] = useState(false);

    function closeModal(){
        setShowCalculationNameEditFlag(false);
        setShowCalculatorFlag(false);
    }

    // Handler to update individual fields
    const handleChange = (field, value) => {
        setCalculatorModalValues((prevValues) => ({
            ...prevValues,
            [field]: value
        }));
    };

    function showCalculationNameEditModal(){
        setShowCalculationNameEditFlag(true);
    }

    return (
        <>
            <div className="modal" style={{display: "flex"}}>
                <div className="modal-content-centered">
                    <span onClick={closeModal} className="close">&times;</span>
                    <div className="modal-section">
                        <a onClick={showCalculationNameEditModal} style={{fontSize: "21px", fontWeight: "600", textAlign: "center", margin: "auto", paddingTop: "14px", paddingBottom: "0px", cursor: "pointer"}}>{calculatorModalValues.name}</a>
                        <a style={{fontSize: "18px", fontWeight: "600", textAlign: "center", margin: "auto", paddingTop: "14px", paddingBottom: "14px"}}>GOAL CALORIES</a>
                        <div className="setsReps" style={{width: "400px", paddingBottom: "10px", fontSize: "17px"}}>

                            <a style={{color: "var(--color-exerprise-orange)"}}>{addCaloricDataCaloriesNeeded}</a>

                            <select style={{color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold", width: "42px", fontSize: "17px"}} value={calculatorModalValues.operator} onChange={(e) => handleChange("operator", parseInt(e.target.value, 10))}>
                                <option value="0">+</option>
                                <option value="1">-</option>
                            </select>

                            <input style={{color: "green", textAlign: "center", fontWeight: "bold"}} size={5} pattern={"[0-9]*"} value={calculatorModalValues.operand} onChange={(e) => handleChange("operand", e.target.value)}/>
                            <a>=</a>
                            <a style={{color: "var(--color-exerprise-blue)"}}>{calculatorModalValues.goalCalories}</a>

                        </div>
                        <div className={"exerciseCellRightDiv exerciseCellRightDivPadding "} style={{ margin: "auto", paddingBottom: "20px"}}>
                            <a style={{fontSize: "18px", fontWeight: "600", textAlign: "center", margin: "auto", paddingTop: "14px", paddingBottom: "14px"}}>MACROS</a>
                            <div className="setsReps" style={{width: "400px", paddingBottom: "10px"}}>
                                <div className="sets-cell" style={{textAlign: "center"}}>
                                    <a>CARBS</a>
                                    <div className="nutrition-goal-headers">{Math.trunc(((calculatorModalValues.carbs / 100) * calculatorModalValues.goalCalories)/4)}g</div>
                                    <select style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} value={calculatorModalValues.carbs} onChange={(e) => handleChange("carbs", parseInt(e.target.value, 10))}>
                                        {Array.from({ length: 101 }, (_, index) => (
                                            <option key={index} value={index}>
                                                {index}%
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="sets-cell" style={{textAlign: "center"}}>
                                    <a>PROTEINS</a>
                                    <div className="nutrition-goal-headers">{Math.trunc(((calculatorModalValues.proteins / 100) * calculatorModalValues.goalCalories)/4)}g</div>
                                    <select style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} value={calculatorModalValues.proteins} onChange={(e) => handleChange("proteins", parseInt(e.target.value, 10))}>
                                        {Array.from({ length: 101 }, (_, index) => (
                                            <option key={index} value={index}>
                                                {index}%
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="sets-cell" style={{textAlign: "center"}}>
                                    <a>FATS</a>
                                    <div className="nutrition-goal-headers">{Math.trunc(((calculatorModalValues.fats / 100) * calculatorModalValues.goalCalories)/9)}g</div>
                                    <select style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} value={calculatorModalValues.fats} onChange={(e) => handleChange("fats", parseInt(e.target.value, 10))}>
                                        {Array.from({ length: 101 }, (_, index) => (
                                            <option key={index} value={index}>
                                                {index}%
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {(calculatorModalValues.carbs + calculatorModalValues.proteins + calculatorModalValues.fats) === 100 ?
                                <a style={{fontSize: "16px", fontWeight: "700", textAlign: "center", margin: "auto", paddingTop: "10px"}}>TOTAL: <a style={{color: "green"}}>100%</a></a>
                                :
                                <a style={{fontSize: "16px", fontWeight: "700", textAlign: "center", margin: "auto", paddingTop: "10px"}}>TOTAL: <a style={{color: "red"}}>{calculatorModalValues.carbs + calculatorModalValues.proteins + calculatorModalValues.fats}%</a></a>
                            }

                        </div>
                        {
                            calculatorModalEditMode ?
                                <>
                                    <button className="normal-button-system" style={{width: "180px", margin: "auto", marginBottom: "12px"}} onClick={updateCalculation}>Update</button>
                                    <button className="normal-button-system" style={{backgroundColor: "var(--color-exerprise-purple)", width: "180px", margin: "auto", marginBottom: "12px"}} onClick={deleteCalculation}>Delete</button>
                                </>
                                :
                                <button className="normal-button-system" style={{width: "180px", margin: "auto", marginBottom: "12px"}} onClick={addCalculation}>Add Calculation</button>
                        }
                    </div>
                </div>
            </div>
            {showCalculationNameEditFlag && <CalculationNameEditModal setShowCalculationNameEditFlag={setShowCalculationNameEditFlag} calculationName={calculatorModalValues.name} handleChange={handleChange} />}
        </>
    );
}

export default CalculatorModal;