import React, {useState} from 'react';
import PhaseTabCell from "../PhaseTabCell";
import WeekTabCell from "../WeekTabCell";
import checkIcon from "../../components/assets/check_circle_24dp_4DD4B1_FILL1_wght400_GRAD0_opsz24.svg";

function ProgressTableModal({ progressJSON, progressProgramJSON, programInfoJSON, setShowProgressTableModalFlag }) {

    const [calendarLayout, setCalendarLayout] = useState(false);
    const [selectedPhaseIndex, setSelectedPhaseIndex] = useState(0);
    const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);

    function showCalendarLayout(boolean){
        if (boolean){
            setCalendarLayout(true);
        } else {
            setCalendarLayout(false);
        }
    }

    function closeModal(){
        setShowProgressTableModalFlag(false);
    }

    // Helper function to parse keys like "P1W1D2" into phase, week, and day numbers.
    const parseKey = (key) => {
        const regex = /^P(\d+)W(\d+)D(\d+)$/;
        const match = key.match(regex);
        if (match) {
            return {
                phase: match[1],
                week: match[2],
                day: match[3],
            };
        }
        return null;
    };

    // Define some inline styles for the table
    const tableStyle = {
        width: 'auto',        // Let the table size itself to its content
        margin: '0 auto',      // Center the table horizontally
        fontSize: '14px',      // Reduce the font size
        borderCollapse: 'collapse',
    };

    const thStyle = {
        padding: '8px',
        border: '1px solid #ccc',
        backgroundColor: 'var(--color-exerprise-blue)',
        color: 'white'
    };

    const tdStyle = {
        padding: '8px',
        border: '1px solid #ccc',
        textAlign: 'center',
    };

    const tdWkStyle = {
        padding: '8px',
        border: '1px solid #ccc',
        textAlign: 'center',
        backgroundColor: 'var(--color-exerprise-orange)',
        color: 'white',
        fontWeight: 'bold'
    };

    const roundTitleDivStyle = {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '42px',
    }

    const roundTitleStyle = {
        fontSize: '42px',
        fontWeight: 'bold',
        color: 'var(--color-exerprise-blue)',
        textDecoration: "underline"
    }

    const roundStartDateStyle = {
        fontSize: "16px",
        fontWeight: 'bold',
        color: 'var(--color-exerprise-orange)',
    }

    function dayIsFinished(phaseIndex, weekIndex, dayIndex){
        const keyString = "P" + (phaseIndex + 1) + "W" + (weekIndex + 1) + "D" + (dayIndex + 1);
        if (progressJSON.hasOwnProperty('progress') && progressJSON.progress.length > 0) {
            if (progressJSON.progress[0].hasOwnProperty(keyString)){
                return true;
            }
        }
        return false;
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <div style={{margin: "auto"}}>
                        <a style={{fontSize: "21px", fontWeight: "500"}}>{programInfoJSON.name}</a>
                        <div>
                            <div className={"progress-layout-tabs" + ( (!calendarLayout) ? " progress-layout-tabs-selected" : "")} onClick={() => showCalendarLayout(false)}>{"Default View"}</div>
                            <div className={"progress-layout-tabs" + ( calendarLayout ? " progress-layout-tabs-selected" : "")} onClick={() => showCalendarLayout(true)}>{"Calendar View"}</div>
                        </div>
                    </div>

                    {calendarLayout ?
                        //calendar layout
                        <div style={{margin: "auto"}}>
                            {progressJSON?.progress?.map((round, roundIndex) => {

                                // Compute the minimum timestamp for the round.
                                const timestamps = Object.values(round)
                                    .map(value => parseInt(value, 10))
                                    .filter(value => !isNaN(value));
                                const minTimestamp = Math.min(...timestamps);
                                const startDate = new Date(minTimestamp * 1000);
                                const formattedStartDate = startDate.toLocaleDateString(navigator.language, {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric'
                                });

                                // Group keys by phase and week.
                                const phases = {};
                                Object.keys(round).forEach((key) => {
                                    const parsed = parseKey(key);
                                    if (parsed) {
                                        const { phase, week, day } = parsed;
                                        // Initialize groupings if they don't exist yet.
                                        if (!phases[phase]) {
                                            phases[phase] = {};
                                        }
                                        if (!phases[phase][week]) {
                                            phases[phase][week] = {};
                                        }
                                        phases[phase][week][day] = round[key];
                                    } else {
                                        console.log("not parsed");
                                    }
                                });

                                return (
                                    <div key={round} style={{ marginBottom: '40px' }}>
                                        <div style={roundTitleDivStyle}>
                                            <a style={roundTitleStyle}>ROUND {roundIndex + 1}</a>
                                            <a style={roundStartDateStyle}>Started: {formattedStartDate}</a>
                                        </div>
                                        {Object.keys(phases).map((phaseKey) => {
                                            const weeks = phases[phaseKey];

                                            // Determine the maximum day number across all weeks in this phase.
                                            let maxDays = 0;
                                            Object.values(weeks).forEach((days) => {
                                                const dayNumbers = Object.keys(days).map(Number);
                                                const weekMax = dayNumbers.length > 0 ? Math.max(...dayNumbers) : 0;
                                                if (weekMax > maxDays) {
                                                    maxDays = weekMax;
                                                }
                                            });

                                            // Sort week numbers so they appear in order.
                                            const sortedWeeks = Object.keys(weeks).sort((a, b) => a - b);

                                            return (
                                                <div key={phaseKey} style={{ marginBottom: '20px' }}>
                                                    <h3>Phase {phaseKey}</h3>
                                                    <table style={tableStyle}>
                                                        <thead>
                                                        <tr>
                                                            <th style={thStyle}>Week</th>
                                                            {Array.from({ length: maxDays }, (_, i) => (
                                                                <th key={i} style={thStyle}>Day {i + 1}</th>
                                                            ))}
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {sortedWeeks.map((weekKey) => {
                                                            const days = weeks[weekKey];
                                                            return (
                                                                <tr key={weekKey}>
                                                                    <td style={tdWkStyle}>Week {weekKey}</td>
                                                                    {Array.from({ length: maxDays }, (_, i) => {
                                                                        const dayNumber = (i + 1).toString();
                                                                        const timestamp = days[dayNumber];
                                                                        let cellContent = '';
                                                                        if (timestamp) {
                                                                            // Convert the timestamp (assumed to be in seconds) into a readable date string.
                                                                            // By default, the code uses JavaScript’s Date object, which displays the time in the user's local time zone (i.e. the time zone set on their computer or browser).
                                                                            // If you need a specific time zone, you can pass an options object to toLocaleString. Like this: .toLocaleString('en-US', { timeZone: 'UTC' });
                                                                            const date = new Date(parseInt(timestamp, 10) * 1000);
                                                                            const userLocale = navigator.language; //This ensures the date is formatted according to the user's locale
                                                                            cellContent = date.toLocaleString(userLocale,{
                                                                                weekday: 'short',  // "Monday", "Tuesday", etc.
                                                                                year: 'numeric',
                                                                                month: 'short',
                                                                                day: 'numeric',
                                                                                hour: '2-digit',
                                                                                minute: '2-digit',
                                                                                // timeZone: 'UTC'   // Or remove/replace with your desired time zone
                                                                            });
                                                                        }
                                                                        return <td key={i} style={tdStyle}>{cellContent}</td>;
                                                                    })}
                                                                </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>

                        :
                        //default layout
                        <div style={{margin: "20px auto"}}>
                            <div className="phases">
                                {progressProgramJSON.p?.map((phase, index) => <PhaseTabCell index={index} selected={index===selectedPhaseIndex} setSelectedPhaseIndex={setSelectedPhaseIndex} key={index} />)}
                            </div>
                            <div className="weeks">
                                {progressProgramJSON.p && progressProgramJSON.p[selectedPhaseIndex].ph.map((week, index) => <WeekTabCell index={index} programType={progressProgramJSON.tp} selected={index===selectedWeekIndex} setSelectedWeekIndex={setSelectedWeekIndex} key={index} />)}
                            </div>
                            <div className="workout-column">
                                {progressProgramJSON.p && progressProgramJSON.p[selectedPhaseIndex].ph[selectedWeekIndex].wk.map((day, index) => (
                                    <div key={index} className="exercise-cell">
                                        <div style={{margin: "auto 0"}}>
                                            {dayIsFinished(selectedPhaseIndex, selectedWeekIndex, index) ?
                                                <img src={checkIcon} style={{margin: "10px", width: "28px", height: "28px"}} className="filter-green swap-icon" alt={"done icon"}/>:
                                                <div style={{margin: "10px", width: "28px", height: "28px"}}></div>
                                            }
                                        </div>
                                        <div style={{textAlign: "left"}}>
                                            <a style={{color: "var(--color-exerprise-orange)", fontWeight: "bold"}}>Day {index+1}</a>
                                            <h5 className="font-semibold">{day.tl}</h5>
                                            <div className="exercise-separater"></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ProgressTableModal;