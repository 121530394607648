import React from 'react';
import copyIcon from "../assets/content_copy_FILL1_wght400_GRAD0_opsz24.svg";
import deleteIcon from "../assets/delete_FILL1_wght400_GRAD0_opsz24.svg";
import {truncateToTwoDecimals} from "../../utils/utils";

function ProgramsRow({index, loadSelectedProgram, programInfo, deleteProgram, duplicateProgram, setShowDescriptionModalFlag, setDescriptionModaText, loadSelectedProgress}) {

    function showDescriptionModal(){
        setDescriptionModaText(programInfo.desc);
        setShowDescriptionModalFlag(true);
    }

    const maxDescriptionLength = 35;

    function getDescriptionString(){
        let desc = programInfo.desc;
        if (desc.length > maxDescriptionLength){
            return desc.substring(0, maxDescriptionLength) + "...";
        }
        return desc;
    }

    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };

    return (
        <>
            <tr>
                <td>{index+1}</td>
                <td style={{textAlign: "left", cursor: "pointer"}} onClick={() => loadSelectedProgram(programInfo)}>{programInfo.name}</td>
                {programInfo.desc ?
                    programInfo.desc.length > maxDescriptionLength ?
                    <td style={{textAlign: "left", cursor: "pointer"}} onClick={() => showDescriptionModal()}>{getDescriptionString()}</td> :
                        <td style={{textAlign: "left"}}>{getDescriptionString()}</td> :
                    <td></td>}
                {/*<td>{programInfo.created?.toDate().toDateString()}</td>*/}
                <td>{programInfo.updated?.toDate().toLocaleDateString("en-US", dateOptions)}</td>
                <td style={{textAlign: "left", cursor: "pointer"}} onClick={() => loadSelectedProgress(programInfo)}>{programInfo.hasOwnProperty("percentProgress") ? truncateToTwoDecimals(programInfo.percentProgress) + "%" : "-"}</td>
                <td>{programInfo.programID}</td>
                <td>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{display: "flex"}} className={"tooltip"}>
                            {/*<img src={deleteIcon} className="filter-red swap-icon" style={{marginRight: "-7px"}} alt={"delete icon"} onClick={deleteProgram} />*/}
                            <img src={copyIcon} className="filter-grey swap-icon" alt={"copy icon"} onClick={() => duplicateProgram(programInfo)} />
                            <span className="tooltiptext sets-cell-tooltiptext" style={{marginLeft: "-52px"}}>Duplicate Program</span>
                        </div>
                        {/*<img src={copyIcon} className="filter-grey swap-icon" alt={"copy icon"} />*/}
                        <div style={{display: "flex"}} className={"tooltip"}>
                            <img src={deleteIcon} className="filter-red swap-icon" style={{marginRight: "-7px"}} alt={"delete icon"} onClick={() => deleteProgram(programInfo)} />
                            <span className="tooltiptext sets-cell-tooltiptext" style={{marginLeft: "-42px"}}>Delete Program</span>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    );
}

export default ProgramsRow;