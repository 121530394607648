import React from 'react';

function AddCaloricDataModal({addCaloricDataModalValues, setProgramJson, setShowAddCaloricDataModalFlag, addCaloricDataCaloriesNeeded}) {
    function closeModal(){
        setShowAddCaloricDataModalFlag(false);
    }

    // Handler to update individual fields
    const handleChange = (field, value) => {
        setProgramJson((prevValues) => ({
            ...prevValues,
            cal_data: {
                ...prevValues.cal_data,
                [field]: value
            }
        }));
    };

    function selectedUnitIsMetric(){
        return addCaloricDataModalValues.units !== 0;
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>Enter caloric data:</a>
                    {/*<input className="exerciseName" style={{marginTop: "20px", marginBottom: "20px"}} size={49} value={calories} onChange={e => setCalories(e.target.value)} />*/}
                    <div className={"exerciseCellRightDiv exerciseCellRightDivPadding "}>
                        <div className="setsReps" style={{width: "700px", paddingBottom: "10px"}}>
                            <div className="sets-cell" style={{textAlign: "center"}}>
                                <div className="nutrition-goal-headers">UNITS</div>
                                <select style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} value={addCaloricDataModalValues.units} onChange={(e) => handleChange("units", parseInt(e.target.value, 10))}>
                                    <option value="0">US</option>
                                    <option value="1">METRIC</option>
                                </select>
                            </div>
                            <div className="sets-cell" style={{textAlign: "center"}}>
                                <div className="nutrition-goal-headers">AGE</div>
                                <input style={{marginTop: "10px", color: "green", textAlign: "center", fontWeight: "bold"}} size={5} pattern={"[0-9]*"} value={addCaloricDataModalValues.age} onChange={(e) => handleChange("age", e.target.value)}/>
                            </div>

                                {/* Conditionally Render based on `units` */}
                                {!selectedUnitIsMetric() ? (
                                    <>
                                        <div className="sets-cell" style={{textAlign: "center"}}>
                                            <div className="nutrition-goal-headers">HEIGHT (FT IN)</div>
                                            <input style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} size={5} value={addCaloricDataModalValues.height_feet} onChange={(e) => handleChange("height_feet", e.target.value)}/>
                                            <input style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} size={5} value={addCaloricDataModalValues.height_inch} onChange={(e) => handleChange("height_inch", e.target.value)}/>
                                        </div>
                                        <div className="sets-cell" style={{textAlign: "center"}}>
                                            <div className="nutrition-goal-headers">WEIGHT (LBS)</div>
                                            <input style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} size={5} value={addCaloricDataModalValues.weight_lbs} onChange={(e) => handleChange("weight_lbs", e.target.value)}/>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="sets-cell" style={{textAlign: "center"}}>
                                            <div className="nutrition-goal-headers">HEIGHT (CM)</div>
                                            <input style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} size={5} value={addCaloricDataModalValues.height_cm} onChange={(e) => handleChange("height_cm", e.target.value)}/>
                                        </div>
                                        <div className="sets-cell" style={{textAlign: "center"}}>
                                            <div className="nutrition-goal-headers">WEIGHT (Kg)</div>
                                            <input style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} size={5} value={addCaloricDataModalValues.weight_kg} onChange={(e) => handleChange("weight_kg", e.target.value)}/>
                                        </div>
                                    </>
                                )}


                            <div className="sets-cell" style={{textAlign: "center"}}>
                                <div className="nutrition-goal-headers">GENDER</div>
                                <select style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} value={addCaloricDataModalValues.gender} onChange={(e) => handleChange("gender", parseInt(e.target.value, 10))}>
                                    <option value="0">MALE</option>
                                    <option value="1">FEMALE</option>
                                </select>
                            </div>
                            <div className="sets-cell" style={{textAlign: "center"}}>
                                <div className="nutrition-goal-headers">ACTIVITY</div>
                                <select style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} value={addCaloricDataModalValues.activity} onChange={(e) => handleChange("activity", parseInt(e.target.value, 10))}>
                                    <option value="0">SEDENTARY</option>
                                    <option value="1">LIGHT</option>
                                    <option value="2">MODERATE</option>
                                    <option value="3">ACTIVE</option>
                                    <option value="4">VERY ACTIVE</option>
                                    <option value="5">EXTRA ACTIVE</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <a style={{fontSize: "16px", fontWeight: "600", textAlign: "center", margin: "auto", paddingTop: "14px", paddingBottom: "14px"}}>CALORIES NEEDED: {addCaloricDataCaloriesNeeded}</a>
                    {/*<button className="normal-button-system" style={{width: "140px", margin: "auto", marginBottom: "12px"}}>{"Add Data"}</button>*/}
                </div>
            </div>
        </div>
    );
}

export default AddCaloricDataModal;